@import "/src/styles/vars.scss";
.nav{
  height: 88px;
  background: white !important;
  box-shadow: 0 7px 9px -3px #ffffff59 !important;
  border-bottom: 1.25px solid #d5e0d5;
  .navbar {
    background: transparent;
    height: 100%; 
    padding: 14px 0 !important;
    .navLogo {
      padding: 10px;
      cursor: pointer;
    }
    .navList {
      list-style: none;
      padding: 10px;
      margin: 0;
      display: flex;
      li {
       .link {
          text-transform: capitalize;
          color: $TextColor;
          fill: $TextColor;
         
          font-family: "Poppins", Sans-serif;
          font-size: 16px;
          font-weight: 500;
    
          transition: 0.4s;
          line-height: 24px;
         
          align-items: center;
          &:hover {
            color: $MainColor;
          }
        }
      a {
          text-transform: capitalize;
          color: $TextColor;
          fill: $TextColor;
          margin: 0 16px;
          padding: 6px 0;
          font-family: "Poppins", Sans-serif;
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          transition: 0.4s;
          line-height: 24px;
          display: flex;
          align-items: center;
          &:hover {
            color: $MainColor;
          }
        }
        
      }
    }
    .navIcons {
      button {
        &:hover {
          background-color: unset !important;
        }
        color: black;
        span{
          svg{
            width: 18px;
            height: 18px;
          }
        }
        span{
          font-size: 14px;
        }
      }
    }
  }
}
.mobile-menu-icon{
  color:$MainColor
}
.no-left-padding{
  padding-left: 0 !important;
}
.selected{
  background:gray
}
