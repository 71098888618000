.actions-end {
  display: flex;
  justify-content: right;
  margin-top: 16px;

  @media (max-width: 900px) {
    justify-content: center;

    button {
      width: 100%;
    }
  }
}

.small-btn {
  button {
    // line-height: 0.2;
    // padding: 10px 5px;
    // height: 44px ;
    // display: flex;
    // font-size: 15px;
    line-height: 0.2;
    padding: 10px;
    height: 30px;
    display: flex;
    font-size: 12px;
    width: fit-content !important;

    .MuiCircularProgress-root {
      width: 11px !important;
      height: 11px !important;
    }

    @media (max-width: 900px) {
      height: auto !important;
      line-height: normal;
    }
  }
}

.actions-center {
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    button {
      // width: 100%;
    }
  }
}

.custom-action {
  display: flex;
  justify-content: end;

  @media (max-width: 900px) {
    justify-content: center;

    button {
      width: 100%;
    }
  }
}

.custom-action button {
  // padding: 5px 10px !important;
  // height: auto !important;
}

.custom-action .cancel button {
  background: white !important;
  color: #adaaaa !important;
  border: 1.25px solid #adaaaa;
}

.custom-action .decline button {
  background: #e0203f !important;
  border-color: #e0203f !important
}

.custom-action .info button {
  background: #2046e0 !important;
  border-color: #2046e0 !important
}

.custom-action .approve button {
  background: #06ab2f !important;
  border-color: #06ab2f !important;
}

.custom-action-fill button {
  background: #f26400 !important;
  border: 1px solid #f26400;
  color: white !important;

  // padding: 5px 10px !important;
  // height: auto !important;
  .MuiCircularProgress-svg {
    color: #ffffff !important;
  }

  @media (max-width: 900px) {

    width: 100%;
  }

}

.rounded-action button {
  min-height: 20px;
  border-radius: 25px;
  padding: 4px 20px !important;
  height: auto;
  width: max-content;

}

.keybord-key {
  box-sizing: border-box;
  font-size: 11px;
  text-align: center;
  width: 50px;
  color: #555;
  margin: 0 4px;
  height: 20px;
  border-color: #f2f2f2;
  border-style: solid;
  text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
  border-width: 1px;
  border-radius: 10px;
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}