.posts{
    display: block;
    text-align: center;
    padding-top: 50px;
    .actions{
        display: flex;
        justify-content: center;
        padding:28px 0px 50px 0px;
       
    }
    .jobs_list {
        position: relative;
        .itemCarousal {
          padding-left: 16px;
          padding-top: 16px;
        }
      }
}

.arrow {
    button {
      background: none;
      border: none;
      position: absolute;
    }
    .right{
      right: -50px;
      top: 50%;
      transform: rotate(180deg);
    }
    .left{
      left: -50px;
      top: 50%;
    }
  }