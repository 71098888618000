@import "/src/styles/vars.scss";
.page{
    background-color: white;
    // padding: 30px 0px;
    padding-bottom: 30px;
    // border-bottom: 1px solid #e6e6e6;
    margin: 88px 0px 12px 0px;
    .profile-info{
        a{
            color:$MainColor ;
            font-weight: 300;
            cursor: pointer;
        }
        p{
            font-size: 18px;
            font-weight: 600;
            margin: 24px 0px;
            
            
           
            
            span{
                font-weight: 300;
                padding-left: 6px;
            }
        }
       
    }
}