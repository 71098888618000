@import '/src/styles/vars.scss';

.login-form{
  background: white !important;
    // display: flex;
    align-content: center;
    margin-bottom: 50px;
  .logo {
      display: none;
      margin-bottom: 10px;
    }
  .form{
    padding-top: 30px;
    display: block;
    position: relative;
    height: fit-content;
    margin: auto 50px;
    width: 70%;
    min-height: 100%;
       
    .hint{
      font-size: 15px;
      a{
        color:$MainColor;
        text-decoration: underline;
        cursor: pointer;
        
      }
    }
    .actions{
        display: flex;
        justify-content: end;
        // margin-top: 1.5rem;
      
    }
    .contact_us{
      // position: absolute;
      // bottom: 15px;
      // left: 0px;
      width: 100%;
      font-size: 15px;
      a{
        color:$MainColor;
        text-decoration: underline;
        cursor: pointer;
        
      }
    }
  }
  @media (max-width: 900px) {
    .form{
      width: auto;
      margin: 0 25px;
  
    }
    .logo {
      display: block;
    }

  }
  
}