.appointments-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    // margin: auto;
    // margin-bottom: 10px;
    flex-wrap: wrap;

    // justify-content: center;
    li {
        width: auto;
        background: rgba(242, 100, 0, 0.03);
        border: 1.25px solid rgba(242, 100, 0, 0.25);
        padding: 4px 15px;
        display: flex;
        gap: 10px;
        border-radius: 8px;
        font-size: 13px;
        position: relative;

        .period {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
        }

        .time {
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
        }

        .icon {
            width: 25px;
            height: 25px;
            transform: scale(0.75);

            position: absolute;
            right: -9px;
            top: -9px;
            color: white !important;
            background: #222222;
            border-radius: 50%;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                color: white !important;
            }
        }
    }
}