@import "/src/styles/vars.scss";
.form{

      .hint_lable {
        color: rgba(0, 0, 0, 0.56);
        font-size: 14px;
        font-weight: 300;
        margin-top: 0px;
        line-height: 18px;
        display: block;
      }
      .radioGroup {
        margin-top: 16px;
        label {
          // color: rgba(0, 0, 0, 0.6);
          font-size: 0.85em;
          
        }
      }
      .notificationsHint {
        font-size: 12px;
      }
    
      .actions {
        display: flex;
        justify-content: end;
      }

}
