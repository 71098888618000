@import '../styles/global.scss';
.page {
    background-color: white;
    margin: 120px 0 12px 0;
    min-height: calc(100vh - 120px);
    @media (max-width: 991px) {
      height: 100%;
      margin: 100px 0 12px 0;
    }
  }

