@import "/src/styles/vars.scss";
.page-content{
  overflow: auto;
  max-height: 100vh;
}

.signUp-form {
  height: 100%;
  display: flex;
  // justify-content: left !important;
  align-content: center;
 
  padding: 50px 0;
  // background:white;
  // border-bottom-right-radius: 125px;

  .logo {
    display: none;
   margin-bottom: 10px;
  }
  .form {
    display: block;
    height: fit-content;
    margin: auto 50px;
    width: 70%;
    // h3 {
    //   color: #222222;
    //   font-weight: 700;
    //   font-size: 24px;
    //   line-height: 48px;
    //   margin-bottom: 5px;
    // }
    // p {
    //   color: #222222;
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 20px;
    //   line-height: 24px;
    //   margin: 0;
    // }
    .requarid {
      font-family: "Poppins", Sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: gray;
    }
    .radioGroup {
      // margin-top: 16px;
      label {
        // color: rgba(0, 0, 0, 0.6);
        font-size: 1rem;
        
      }
    }
    .hint_lable {
      color: rgba(0, 0, 0, 0.56);
      font-size: 14px;
      font-weight: 300;
      margin-top: 4px;
      line-height: 14px;
      display: block;
    }
    .notificationsHint {
      font-size: 12px;
    }
    .hint {
      font-size: 14px;
      a {
        color: $MainColor;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .actions {
      display: flex;
      justify-content: end;
    }
    .message {
      border: 1px solid;
      padding: 10px;
      border-radius: 8px;
      font-size: 12px;
      margin-top: 20px;
    }
  }
  @media (max-width: 900px) {
    .form{
        width: 100%;
    }
    .logo{
      display: block;
    }
    .actions {
      margin-top: 20px;
      button{
        width: 100%;
      }
    }
  }
}
