.tabs  .tab {
      text-transform: none;
      font-weight: 400;
      font-size: 16px;
      //line-height: 24px;
      color: #222222;
      //margin: 6px 12px;
      display: flex;
      //justify-content: end;
      //padding: 0;
      min-width: auto;
      background-color:none;
    }