.appointments-list{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    li{
        width: auto;
        background: rgba(39, 174, 96, 0.03);
        //  border: 1.25px solid rgba(39, 174, 96, 0.25);
        padding: 10px;
        display: flex;
        gap: 10px;
        border-radius:8px;
        cursor: pointer;
        .period{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
        }
        .time{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
        }
    }
}
.expanded-card{
    // position: absolute;
}