$button1: #7AB046;
$button2: #1E91EA;
$button3 : #f26400;
$button4: #414BB1;
$button5: #938D8D;
body {

}
.title{
  display: flex;
  justify-content: space-between !important;
  font-size: large;
  #closePopup{
    font-size:xxx-large;
    margin-left: 4rem;
    color: black;
    cursor: pointer;
  }
}
.action{
  margin-block: 2rem;
}

.MuiTypography-root {
  font-family: "Poppins", Sans-serif !important;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Add space between buttons */
  margin-top: 1rem;

  button {
    font-family: "Poppins", Sans-serif !important;
  }
}

.btn {
  color: white;
  font-size: small;
  padding: .6rem;
  text-transform: none;
  flex-grow: 1;
}

#button1 {
  background-color: #7AB046;
}

#button2 {
  background-color: #1E91EA;
}

#button3 {
  background-color: #f26400;
}

#button4 {
  background-color: #414BB1;
}

#button5 {
  background-color: #938D8D;
}

/* Media Query for Small Screens */
@media screen and (max-width: 767px) {
  .btn {
    width: 100%;
  }
}
