@import "/src/styles/vars.scss";
.posts{
    display: block;
    text-align: center;
    padding-top: 50px;
    .actions{
        display: flex;
        justify-content: center;
        padding:28px 0px 50px 0px;
       
    }
    .jobs_list {
        position: relative;
        .itemCarousal {
          padding-left: 16px;
          padding-top: 16px;
        }
      }
}

.arrow {
    button {
      background: none;
      border: none;
      position: absolute;
    }
    .right{
      right: -50px;
      top: 50%;
      transform: rotate(180deg);
    }
    .left{
      left: -50px;
      top: 50%;
    }
  }
  .section_header {
    text-align: center;
    padding: 10px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 48px;
      color: $TextColor;
      margin-bottom: 10px;
      margin-top: 0;
    }
    .description {
      margin-top: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      max-width:500px;
      margin: auto;
      color: $TextColor;
  
     
    }
    margin-bottom: 40px;
  }