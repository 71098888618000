@import '/src/styles/vars.scss';
.signUp-page{
    min-height: 100vh;
    overflow: hidden;
    .signUp-page-row{
        // border-bottom: 1.25px solid #e2e1e1;
        height: 100%;
        @media (max-width: 900px) {
            .left-col{
                display: none; 
            }

          }
    }
}