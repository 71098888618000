.helpContainer {
  background-color: #5A5A5A;
  position: absolute;
  padding-left: 1vw;
  display: flex;
  align-items: center;
  .guidance{
    color: white;
    font-size: small;
  }
  .button{
    color: #97BDDA;
    font-size: small;
    text-transform: none;
  }
  Button{
    padding: 0;
  }
  .closeButton{
    font-size: x-large;
    padding: 0%;
    color: rgb(246, 246, 248);
  }
}
#ToolTip{
  display: flex;
  justify-content: end;
  flex-wrap: nowrap;
  box-sizing: content-box;
}
