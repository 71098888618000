@import "/src/styles/vars.scss";

.header {
  background-color: white;
  padding: 0;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 40px;

  .container {
    height: 100%;

    .grid {
      display: flex;
      align-items: center;
      // height: 100%;
    }

    .header_info {
      display: flex;
      align-items: center;

      .header_text {
        width: 400px;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: $TextColor;

        @media (max-width: 900px) {
          font-weight: 600;
          font-size: 21px;
          width: 100%;
          text-align: initial;
          line-height: 30px;

        }
      }

    }

    .header_info_box {

      display: flex;

      .info {
        width: 250px;
        background: #27AE6008;
        border: 1.25px solid rgba(106, 106, 106, 0.25);
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        margin-left: auto;

        h6 {
          margin: 0;
          font-size: 12px;
          font-weight: bolder;
        }

        p {
          font-size: 12px;
          margin: 10px 0;
        }

        button {
          background: $MainColor;
          color: white;
          width: 30px;
          height: 30px;
        }
      }
    }

    .univirsity_info {
      justify-content: center;
      margin-top: 80px;
      padding: 0px 0px 70px 0px;

      .card {
        box-shadow: none !important;
        display: flex;
        align-items: center;
        border-radius: 16px;
        button {
          display: flex;
        }

        .img_border {
          border: $MainBorder;
          border-radius: 16px;
          padding: 15px;
          width: 265px;
          height: 175px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;

          img {
            width: 250px;
            height: 130px;
          }
        }

        img {

          width: 206px;
          height: 88px;
        }

        .contint {
          display: flex;
          align-items: center;
          padding: 0;

          h5 {
            font-family: "Poppins", Sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 22px;
            margin: 0px 0px 16px 0px;
          }

          button {
            background: $MainColor;
            color: white;
            width: 30px;
            height: 30px;
            margin: 0 30px;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .grid {
      flex-direction: column;
      row-gap: 2rem;
    }

    .header_info {
      text-align: center;
      justify-content: center;

      .header_text {
        text-align: center !important;
        width: 400px;
        font-style: normal;
        font-weight: 300;
        font-size: 3px;
        line-height: 4px;
        color: $TextColor
      }
    }

    .card {

      flex-direction: column;
      width: 100%;
    }

    .info {
      width: 100%;
    }
    .contint{
      display: block !important;
      text-align: center;
      margin-top: 20px;
      p, button{
        // text-align: center;
        margin: auto !important;
        margin-top: 10px!important;
        margin-bottom: 10px !important;
      }
    }


  }
}