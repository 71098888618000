@import "/src/styles/vars.scss";
.header {
@media(min-width:900px){
  // padding-bottom: 50px;
  margin-bottom: 50px;
}
  
  .info {
   
    h5 {
      color: $TextColor;
    }
    p {
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
      color: $TextColor;
    }
  }
 
}
