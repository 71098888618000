@import "/src/styles/vars.scss";
.section_header {
  text-align: center;
  padding: 10px;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    color: $TextColor;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .description {
    margin-top: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width:500px;
    margin: auto;
    color: $TextColor;

   
  }
  margin-bottom: 40px;
}
