$button1: #7AB046;
$button2: #1E91EA;
$button3 : #f26400;

.title{
  display: flex;
  justify-content: space-evenly;
  font-size: large;
  #closePopup{
    font-size:xxx-large;
    margin-left: 4rem;
    color: black;
    cursor: pointer;
  }

}
.action{
  margin-block: 2rem;
}
.buttons{
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-around;

  .btn{
    color: white;
    font-size: small;
    padding: .6rem;
    text-transform: none;
  }
}

#button1{
  background-color: $button1;
}
#button2{
  background-color: $button2;
}
#button3{
  background-color: $button3;
}
