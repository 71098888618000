@import "/src/styles/vars.scss";

.avalible-jobs {
    
  button {
    width: calc(50% - 10px );
    text-align: left;
    font-size: 10px;
    margin-right: 10px;
  }
}
.selected-jobs{
    p{
        color: gray;
        line-height: 14px;
    }
    button{
        width: auto;
        background:transparent;
        padding: 0;
        cursor: pointer;
      
    }
 
}