@import '/src/styles/vars.scss';
.job-match{
    border: $MainBorder;
    border-radius: 16px;
    margin-bottom: 50px;
    h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 48px;
        color: $TextColor;
      }
    .check-box{
      gap:0.5rem;
      label{
        display: block;
        margin: auto 0;
        color:$TextColor !important
      }
    }
}