@import "/src/styles/vars.scss";

.rightgrid {
  padding: 1rem;

  .text {
    margin: 1rem 3rem;
    @media screen and (max-width: 900px) {
        margin: 1rem 0.5rem;
      }
  }
}
.leftgrid{
  
  padding: 0px 34px 0px 0px;
  margin-right: 30px;
  border-right: 2px solid $backgroundColor;

  .leftgrid-content {
    border-radius: 5px;
  
    // background-color: aqua;
   
    border: 2px solid $backgroundColor;
    .row {
      padding: 1rem;
      border-top: 2px solid $backgroundColor;
    }
  }
}
