@import '../../../styles/vars';
.event {
  display: block;
  background: rgba(242, 100, 0, 0.03);
  border: 1.25px solid rgba(242, 100, 0, 0.25);
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
 
  p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media(max-width:900px){
    display: none;
  }
}
.event-mobile{
  display: none;
  p {
    font-size: 10px;
  }
  svg{
    color:$MainColor
  }
  @media(max-width:900px){
    display: block;
  }
}
