$button1: #7AB046;
$button2: #1E91EA;
$button3 : #f26400;

.title {
  display: flex;
  justify-content: space-between;
  font-size: large;

  #closePopup {
    font-size: xxx-large;
    margin-left: 4rem;
    color: black;
    cursor: pointer;
  }

}

.action {
  margin-block: 2rem;
}

.buttons {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  // padding-inline: .3rem;
  // margin-inline: .3rem;
  .btn {
    // margin-inline: 1vw;
    color: white;
    font-size: small;
    padding: .6rem;
    text-transform: none;
    // padding-inline: .3rem;
    // margin-inline: .3rem;

  }
}

#button1 {
  background-color: $button1;
}

#button2 {
  background-color: $button2;
}

#button3 {
  background-color: $button3;
}

.head_title {
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
}

.quick_title {
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
}
