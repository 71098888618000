@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");
@import "./vars.scss";
@import "./_tabs.scss";
@import "./_form.scss";
@import "./buttons";
@import "./notFound";

/* override browser default */
html,
body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}

/* use viewport-relative units to cover page fully */
body {
  min-height: 100vh;
  width: 100vw;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
button,
input,
label,
div,
title,
strong {
  font-family: "Poppins", sans-serif;
  color: $TextColor;
}

/* include border and padding in element width and height */
* {
  box-sizing: border-box;
}

img {
  // object-fit: contain !important;
}

.Mui-selected,
.Mui-checked,
.Mui-focused {
  color: #f26400 !important;

  .MuiSvgIcon-root {
    color: #f26400 !important;
  }
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f26400 !important;
}

.MuiAppBar-root {
  background: transparent !important;
}

.MuiTouchRipple-root {
  display: none !important;
}

input {
  color: black !important;
}

::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

// :-webkit-scrollbar {
//   width: 6px; /* vertical scrollbar width */

// }

:-webkit-scrollbar-button {
  height: 5px;
  /* vertical scrollbar height */
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: rgb(255, 255, 255);
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* //Main Table style */
.main-table {
  border: 1.25px solid #efeeee;
  border-radius: 8px;
  overflow-x: auto;
}

.main-table table th:not(:last-child),
td:not(:last-child) {
  border-right: 1.25px solid #efeeee;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(39, 174, 96, 0.03) !important;
}

.MuiDayPicker-weekContainer .Mui-selected {
  background: #fffaf7 !important;
}

.MuiMonthPicker-root .Mui-selected,
.MuiClockPointer-thumb,
.MuiClockPointer-root,
.Mui-selected,
.MuiClock-pin {
  background-color: #fffaf7 !important;
  border-color: #fffaf7 !important;
}

// .MuiIconButton-root {
//   background-color: #f26400 !important;
//   .MuiTypography-root{
//       color:white !important;
//   }

// }

.MuiAutocomplete-input {
  padding: 0 !important;
}

.MuiAutocomplete-root .MuiChip-filled {
  height: 20px !important;
}

.MuiAutocomplete-root .MuiFormControl-root {
  height: 100% !important;
  display: grid;
  margin: 0 !important;
}

.MuiChip-root {
  background: #f2640008 !important;
  border: 1px solid #f26400 !important;
}

.page {
  background-color: white;
  margin: 88px 24px 0px 24px;
  min-height: calc(100vh - 120px);
}

@media (max-width: 991px) {
  .page {
    height: 100%;
  }
}

.calender-page {
  @media (max-width: 991px) {

    min-height: calc(100vh);

  }
}

.header {
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #222222;
  margin-bottom: 10px;
  margin-top: 0;
  width: auto;
  width: 100%;
}

.secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 0;
  color: #222222;
  width: max-content;
  width: auto;
}

.table-title {
  font-weight: 500;
  font-size: 14px;
}

.box-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 0;
  color: #222222;
  width: max-content;
  width: auto;
}

.date {
  font-size: 11px;
  color: gray;
  margin: 3px 0;
}

.bg-colored {
  background-color: #f26400;
  color: white;
}

.colored {
  color: #f26400 !important;
}

.colored-gray {
  color: gray;
}

.fill {
  background-color: rgb(106 106 106 / 24%);
}

.popup-box {
  border-radius: 8px;
  padding: 50px 20px;
}

.sub-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  color: #222222;
}

.list-title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #222222;

  @media(max-width:900px) {
    line-height: 24px;
    display: block !important;

    span {
      margin: 0;
    }
  }
}

.main-description {
  display: block;
  font-size: 14px;
  color: #222222;
  // width:100%
}

.description {
  display: block;
  font-size: 11px;
  color: #222222;

  svg {
    width: 12px !important;
    height: 12px !important;
    margin: 0;
  }
}

.required-hint {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: gray;
}

.main-border {
  border: 1.25px solid #efeeee;
  border-radius: 8px;
}

.main-orange-border {
  border: 1.25px solid $MainColor;
}

.main-r-border {
  border-right: 1.25px solid #efeeee;
}

.main-r-border-none {
  @media (max-width: 991px) {
    border-right: none
  }
}

.main-b-border {
  border-bottom: 1.25px solid #e5e5e5;
}

.main-t-border {
  border-top: 1.25px solid #efeeee;
}

.full-radius-border {
  border-radius: 40%;
}

.small-filled-btn {
  height: 20px;
  margin: auto 0px auto 10px;
  color: white;
  background: $MainColor;
  border: $MainColor;
}

.main-link {
  color: #f26400;
  text-decoration: underline;
  cursor: pointer;
}

.center {
  justify-content: center !important;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-x-10 {
  margin: 0 10px;
}

.m-all-10 {
  margin: 10px;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.table-action {
  cursor: pointer;
  display: flex;
}

.table-action svg {
  border-radius: 4px;
  color: #f26400;
  background-color: white;
  border: 1px solid #f26400;
}

.table-action svg:hover {
  color: white;
  background: #f26400;
}

.table-action-check .check-icon {
  // border-radius: 4px;
  cursor: pointer;
  display: flex;
  color: #f26400;
  background-color: white;
  justify-content: center;
  // border: 1px solid #f26400;
}

textarea {
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid #c4c4c5 !important;
  border-radius: 8px !important;
  font-size: 14px;

  &:hover {
    border: 1px solid #c4c4c5 !important;
  }
}

.MuiInputBase-root textarea {
  border: none !important;

  &:hover {
    border: none !important;
  }

  // outline: 0;
}

textarea:focus {
  // border: none !important;
  outline: 0;
}

@media (max-width: 991px) {
  .MuiModal-root .MuiBox-root {
    width: 99% !important;
  }

  .responsive-block {
    display: block;
  }

  .responsive-m-b-10 {
    margin-bottom: 10px;
  }
}

.required {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: gray;
}

.spacer {
  height: 10px;
}

.small-text {
  font-size: 14px;
}

.post {
  box-shadow: none !important;
  // border: 1.25px solid #f5f5f5;
  border: 1.25px solid #efeeee;

  border-radius: 16px !important;
  width: 100%;
  padding: 17px;
  min-height: 185px !important;
}

.student-card {
  box-shadow: none !important;
  // border: 1.25px solid #f5f5f5;
  border: 1.25px solid #efeeee;
  cursor: pointer;
  border-radius: 16px !important;
  width: 100%;
  padding: 17px;
  // min-height: 185px !important;
}

.analysis-card {
  box-shadow: none !important;
  // border: 1.25px solid #f5f5f5;
  border: 1.25px solid #efeeee;

  border-radius: 16px !important;
  width: 100%;
  padding: 17px;

}

.notification-list {
  padding: 10px 15px;
  width: 400px;
}

.notification-list li {
  padding: 10px;
}

.notification-list li:not(:last-child) {
  border-bottom: 1.25px solid #efeeee;
}

.notificatios .notification-item {
  border-bottom: 1.25px solid #efeeee;
  padding: 10px;
}

.notification-item .logo {
  width: 70px;
  height: auto;
  margin-right: 20px;
}

.see-all {
  margin: auto;
  color: #f26400;
}

.no-comments {
  min-height: 500px;
  display: flex;
  align-items: center;
}

.bg-light-green {
  background: rgba(39, 174, 96, 0.03) !important;
}

.bg-light-orange {
  background: rgba(242, 100, 0, 0.03) !important;
}

.border-orangr {
  border-color: #f26400;
}

.border-green {
  border-color: rgba(39, 174, 96, 0.25);
}

.border-green-all {
  border: 1.25px solid rgba(39, 174, 96, 0.25);
}

.gray-box {
  background: rgba(106, 106, 106, 0.03);
  border: 1.25px solid rgba(106, 106, 106, 0.25);
  border-radius: 20px;
  padding: 20px;
}

// form .form-item .MuiPickerStaticWrapper-root {
//   width: fit-content !important;
// }
form .form-item .MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content {
  border: 1.25px solid rgba(39, 174, 96, 0.25);
  border-radius: 8px;
  background: rgba(39, 174, 96, 0.03) !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1.25px solid rgba(165, 164, 164, 0.786) !important;
}

.ql-container {
  border: none !important;
}

.crop-container {
  width: 100%;
  height: 300px;
  border: 2px dashed #6a6a6a40;
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
}

.crop-container svg {
  color: #6a6a6a40;
  font-size: 300px;
  margin: auto;
}

.crop-container video {
  width: 100%;
  height: 100%;
}

.Hired_label {
  color: #06ab2f;
}

.Pending_label {
  color: #b0b005;
}

.Applied_label,
.Approved_label {
  color: #06ab2f;
}

.Rejected_label {
  color: #e0203f;
}

.Declined_label {
  color: #e0203f;
}

.user-name-text-size {
  font-size: 14px;
}

.social-media-list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  justify-content: center;
}

.social-media-list li {
  margin: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6a6a6a;
  color: white;
  width: 34px;
  height: 34px;
  border-radius: 6px;
}

.social-media-list li svg {
  width: 16px;
}

.mobile-view {
  @media (min-width: 899px) {
    display: none !important;
  }
}

.desktop-view {
  @media (max-width: 900px) {
    display: none !important;
  }
}

.responsive-profile-col {
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;

    .info {
      margin: auto;
    }
  }
}

.col-sm {
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
  }
}

.MuiBadge-badge {
  min-width: 10px !important;
  font-size: 9px !important;
  width: 15px;
  padding: 0;
  height: 15px !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: transparent !important;
  color: $MainColor;

  p {
    color: $MainColor;
  }
}

.MuiList-root {
  min-width: 120px;
}

.MuiMenuItem-root {
  font-size: 14px !important;

  p {
    font-size: 14px;
  }

  &:hover {
    background-color: transparent !important;
    color: $MainColor;

    p {
      color: $MainColor;
    }
  }
}

.header-underline {
  border-bottom: 1.25px solid #e5e5e5;
  margin-bottom: 50px;
  padding-bottom: 20px;

  @media (max-width: 900px) {
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.text-underline {
  border-bottom: 1.25px solid #e5e5e5;
  margin-bottom: 20px;
  padding-bottom: 20px;

  @media (max-width: 900px) {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.receive-notifications-checkbox {
  .MuiFormControlLabel-root {
    margin: 0 !important;
    margin-right: 10px;
    margin-bottom: 0px !important;
    display: flex;
    align-items: baseline;

    .MuiButtonBase-root {
      padding: 0;
    }
  }
}

.MuiFormGroup-root {
  gap: 1rem;
}

.MuiFormControlLabel-root {
  margin: 0px !important;
  display: flex !important;
  gap: 0.5rem;
}

.PrivateSwitchBase-root {
  padding: 0 !important;
}

.auto-center {
  @media (min-width: 900px) {
    width: fit-content;
    margin: auto;
  }
}

.fc .fc-button-primary {
  background-color: #f26400 !important;
  border-color: #f26400 !important;
}
.fc-h-event {
  background: transparent !important;
  border: 1.25px solid transparent !important;
  // color: white !important;

}
.fc-icon:before {
  color: white !important;
}

.fc .fc-button-primary:disabled {
  background-color: gray !important;
  border-color: gray !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.MuiRating-icon {
  color: #0d9b0d96;
}

.mouseAction {
  cursor: pointer;

  p {
    font-weight: bold;
  }

  &:hover {
    p {
      color: $MainColor;
    }
  }
}

.colored-header {
  background: #f2640008;
}

.ql-container {
  height: auto;
}

.small-logo {
  width: 60px;
  height: 60px;
}

.fc-scroller-liquid-absolute {
  position: relative !important;
}

.MuiCircularProgress-svg {
  color: $MainColor !important;
}

.sender-message {
  // margin-bottom: 10px;
  // width: fit-content;
  background-color: rgba(242, 100, 0, 0.14);
  padding: 10px;
  border-radius: 20px;
  // margin-left: auto;
  border-top-right-radius: 0;

  // color: white;
  // -webkit-box-shadow: -2px 3px 7px -1px rgb(0 0 0 / 36%);
  // box-shadow: -2px 3px 7px -1px rgb(0 0 0 / 36%);
  .time {
    font-size: 10px;
    color: #6a6a6a;
  }
}

.reciver-message {
  // margin-bottom: 10px;
  // width: fit-content;
  background-color: rgba(106, 106, 106, 0.14);
  padding: 10px;
  border-radius: 20px;
  margin-right: auto;
  border-top-left-radius: 0;

  // border-bottom-left-radius: 30px;
  // color: $MainColor;
  // -webkit-box-shadow: -2px 3px 7px -1px rgb(0 0 0 / 36%);
  // box-shadow: -2px 3px 7px -1px rgb(0 0 0 / 36%);
  .time {
    font-size: 10px;
    color: #6a6a6a;
  }
}

.main-box-shadow {
  -webkit-box-shadow: -0px 0px 8px 0px #f26400;
  box-shadow: 0px 0px 8px 0px #f26400;
}

.add-image {
  position: absolute;
  right: 8px;
  color: white;
  background: $MainColor;
  border: 5px solid white;
  border-radius: 50%;
  bottom: 2px;
}

// .MuiButton-textPrimary{
//   color: $MainColor !important;
// }
.MuiAlert-icon {
  color: white;
}

.MuiTextField-root {
  min-height: 46px !important;
}

///Spiner
/// 
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: $MainColor;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.location-item {
  background: white;
  border: 1px solid #e2e2e2;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  padding: 6px 5px;
}

.MuiInputBase-formControl {
  padding-left: 15px !important;
}

// animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: .0;
  }
}

.animate_pulse {
  animation: pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.label-date {
  width: max-content;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #f26400;
  color: white;
  padding: 2px 7px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}

.quill {
  overflow: hidden !important
}

.ql-editor {
  height: 80% !important;
}

.mentor-card-text {
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-wrap {
  text-wrap: wrap;
}