  .Companies-list{
    list-style: none;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;

    li{
      font-size: 14px;
      margin-right: 20px;
      display: flex;

      display: flex;
    align-items: center;
    // justify-content: center;
    gap: 8px;
      img{
        width: 90px !important;
      }
    }

  }