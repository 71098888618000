@import "/src/styles/vars.scss";

.header {
  .logo{
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
.job-name{
  border-bottom: $MainColor solid 1px;
  width: 8%;
  margin-bottom: 1rem;
}
}
