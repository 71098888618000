.container{
    border: 1px solid #ff000e;
    background-color: #ffbaba4d;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 15px 0px;
    width: fit-content;

    p {
        font-weight: 500;
        font-size: 13px;
        color: #ff000e;
        
    }
        
}