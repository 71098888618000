.Carousel_list {
  position: relative;
  .itemCarousal {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0px;
  }
  .arrow {
    button {
      background: none;
      border: none;
      position: absolute;
    }
    .right {
      right: -34px;
      top: 50%;
      transform: rotate(180deg);
    }
    .left {
      left: -34px;
      top: 50%;
    }
    @media (max-width: 900px) {
      .right {
        right: -10px;
        box-shadow: -3px -3px 4px #8888885e;
        border-radius: 50%;
      }
      .left {
        left: -10px;
        box-shadow: -3px 3px 4px #8888885e;
        border-radius: 50%;
      }
    }
  }
}
.Carousel_list_small {
  button {
    width: 20px;
    height: 20px;
    svg {
      width: 100%;
    }
  }
  .arrow {
    .right {
      right: -20px;
      border-radius: 50%;
    }
    .left {
      left: -20px;
      border-radius: 50%;
    }
  }
}
