@import "/src/styles/vars.scss";

.signUp-form {
  height: 100%;
  display: flex;
  align-content: center;
  overflow: auto;
   max-height: 100vh;
  padding: 50px 0;
  .logo {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 20px 0;
    img {
      width: 120px;
    }
  }
  .form {
    display: block;
    height: fit-content;
    margin: auto 50px;
    width: 70%;
    .requarid {
      font-family: "Poppins", Sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: gray;
    }
    .hint_lable {
      color: rgba(0, 0, 0, 0.56);
      font-size: 14px;
      font-weight: 300;
      margin-top: 4px;
      line-height: 14px;
      display: block;
    }
    .notificationsHint {
      font-size: 12px;
    }
    .hint {
      font-size: 14px;
      a {
        color: $MainColor;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .actions {
      display: flex;
      justify-content: end;
    }
    .message {
      border: 1px solid;
      padding: 10px;
      border-radius: 8px;
      font-size: 12px;
      margin-top: 20px;
    }
  }
  @media (max-width: 900px) {
    .form{
        width: 100%;
    }
  }
}
