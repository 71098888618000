@import "/src/styles/vars.scss";
.container {
  margin-bottom: 50px;
  padding: 16px;
  background-color: #F2640008;
  .info {
    // margin: 0 10px;
    background-color: white;
    padding: 24px 12px;
    border-radius: 8px;
    // margin-bottom: 5px;
    box-shadow: rgba(179, 183, 187, 0.2) 0px 8px 24px;
    h5 {
      color: $TextColor;
    }
    p {
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
      color: $TextColor;
    }
  }
  a,
  .show {
    color: $MainColor;
    text-decoration: underline;
    display: block;
    cursor: pointer;
  }
  .Company-about {
    margin-bottom: 20px;
  }
  .Company-Background {
    margin-bottom: 50px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      // margin-bottom: 10px;
    }
  }
  .company-media {
    .item {
      background-size: cover;
      background-position: bottom;
      height: 200px;
      width: 100%;
      position: relative;
      border-radius:8px;
    }
    .overlay {
      border-radius:8px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      background: #00000066;
      svg {
        color: $MainColor;
        height: 50px;
        width: 50px;
        margin: auto;
        cursor: pointer;
      }
    }
  }
}
