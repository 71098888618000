.form-item {
  margin-bottom: 10px;
  label {
    width: auto;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    strong {
      font-size: 0.85em;
    }
  }
  .link {
    font-size: 14px;
    font-weight: 400;
  }
  .m-b-0 {
    margin-bottom: 0 !important;
  }
  .MuiFormControl-root{
    margin: 0 !important;
  }
}
form .form-item .CheckBoxList label {
  margin: 0;
}
form .form-item .CheckBoxList label .MuiCheckbox-root {
  color: #cacaca;
  padding-left: 0;
}
.MuiCheckbox-root {
  color: #cacaca !important;
  padding-left: 0 !important;
}
form .form-item .CheckBoxList label span {
  font-size: 12px;
}
.input-label {
  color: black !important;
  margin: 0 0 7px 0;
  width: 100%;
  width: auto;
}
.input-label label {
  font-weight: 500;
  font-size: 16px;
  display: block;
  width: auto;
  .star{
    margin-right: 3px;
  }
}
.input-label p {
  color: rgba(0, 0, 0, 0.56);
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  line-height: 14px;
  display: block;
}

.react-tel-input .form-control  {
height: 46px !important;
width: 100% !important;

border-radius: 8px !important;
}
/////////////////
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #f26400;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f26400;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}