@import "/src/styles/vars.scss";
.no-margin{
  margin-top: 0px !important;
}
.footer {
  margin-top: 60px;
  background: #6a6a6a0a;
  padding: 10px 0px 0px 0px;
  .upperFooter {
    border-bottom: 1px solid #d7d7d7;

    .LogoImage {
      padding: 10px;
    }
    .Content {
      padding: 10px;
      margin-bottom: 20px;

      .footerContent {
        display: flex;

        .footer_list {
          width: 20%;
          display: flex;
          justify-content: center;
          padding: 10px;
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            h5 {
              margin: 0px 0px 10px 0px;
              color: $TextColor;
              font-family: "Poppins", Sans-serif;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              margin-bottom: 20px;
            }
            li {
              font-family: "Poppins", Sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              margin: 6px 0;
            }
          }
        }
      }
    }
  }

  .buttomFooter {
    padding: 10px;
    margin-top: 20px;
    .Content {
      display: flex;

      justify-content: space-between;

      padding-bottom: 15px;
      .links {
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: $TextColor;
          margin: 0 16px;
        }
      }
      .copyrights {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $TextColor;
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0;
        li {
          margin: 0 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #6a6a6a;
          color: white;
          width: 34px;
          height: 34px;
          border-radius: 6px;
        }
      }
    }
  }
  @media (max-width: 900px) {
    // margin-top: 0px;
  }
  @media (max-width: 650px) {
    .footerContent {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .footer_list {
        width: 100% !important;
        justify-content: left !important;
    
      }
    }
    .LogoImage {
      display: flex;
      justify-content: center;
    }
    .buttomFooter {
      .Content {
        flex-direction: column;
        display: flex;
        align-items: center;
        row-gap: 1.5rem;
      }
      .Content :nth-child(1) {
        order: 2;
      }
      .Content :nth-child(2) {
        order: 2;
      }
      .Content :nth-child(3) {
        order: 1;
      }
    }
  }
}
