@import "/src/styles/vars.scss";

.banner {
  background:$backgroundColor;
  height: 100%;
  display: block;
  padding: 0px;
  // border: 5px solid $MainColor;
    max-height: 100vh;
  position: relative;
  background-image: url(../../../../assets/images/Registration_image.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  .logo {
    display: flex;
    padding: 20px;
    position: absolute;
    z-index: 99;
    left: 0;

    img {
      width: 120px;
    }
  }
  // .content{

  //   clip-path: polygon(69% 0%, 100% 0%, 80% 38%, 103% 39%, 0% 100%, 39% 50%, 0% 34%);
  //   background:url(../../../../assets/images/studentBg.jpeg);
  //   height: 100%;
  //   padding: 0;
  //   background-size: cover;
  //   background-position: bottom;
  //   transition: all 1 ease-in-out;
  //   opacity: 0;
  //   filter: blur(4px);
  //   animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  
  // }

  // .content {
  //   // background:url(../../../../assets/images/signupbg.png);
  //   width: calc(100% - 40px);
  //   height: 100%;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   position: absolute;
  //   top: 0;
  //   left: 20px;
  //   bottom: 0;
  // }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
