.model_text{
    // width: 50%;
    line-height: 18px;
    margin: 10px 0;
}
.MuiFilledInput-root{
&::after{
    border-bottom: none;
}
}
.popUPBox{
    position: absolute;
   top:50%;
    left: "50%";
    transform: "translate(-50%, -50%)";
    width: "45%";
    background-color: "background.paper";
    border-radius:'16px';
    box-shadow: 0;
    padding: 4rem,
}