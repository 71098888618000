@import "/src/styles/vars.scss";
.container {
 margin-bottom: 50px;
  .item {
    border: $MainBorder;
    border-radius: 16px;
  }
 
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    .icon {
      color: $MainColor;
      padding: 0;
      width: 20px;
      height: 20px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
