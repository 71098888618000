@import "/src/styles/vars.scss";

.inbox-row {
  // min-height: calc(100vh - 130px);
  height: 100%;
  margin-top: 16px;
  padding-bottom: 40px;

  @media (max-width: 900px) {
    flex-direction: column-reverse !important;
    padding-bottom: 0px;

  }

  span {
    margin-left: auto;
    display: block;
    font-size: 12px;
    text-align: right;
  }

  .left-col {
    border-right: $MainBorder;
    padding-right: 5px;
    height: calc(100vh - 230px);
    overflow-y: auto;
    @media (max-width: 900px) {
      border-right: 0;
    }
    .text_mesage {
      font-size: 10px;
    }

    ul {
      .active {
        background: rgba(39, 174, 96, 0.03);
        border: 1.25px solid rgba(39, 174, 96, 0.25);
        // border: 1.25px solid #b8b0b0;
        border-radius: 8px;
      }

      li {
        padding: 10px 15px;

        &:hover {
          background: rgba(39, 174, 96, 0.03);
          border: 1.25px solid rgba(39, 174, 96, 0.25);
          border-radius: 8px;
        }
      }
    }
  }

  .right-col {
    padding: 0 40px;
    height: calc(100vh - 230px);
    display: flex;
    flex-direction: column;

    // justify-content: space-between;
    // overflow-y: auto;
    @media (max-width: 900px) {
      border-bottom: 1.25px solid #e5e5e5;
      padding: 0;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      @media (max-width: 900px) {
        display: block;
        padding: 0;
      }
    }

    .body {
      margin-bottom: 20px;
      height: calc(100vh - 410px);
      overflow-y: auto;
      @media (max-width: 991px) {
         height: auto;
         min-height: 35vh;
         max-height: calc(100vh);
      }

      // padding: 0 10px;
      span {
        margin-left: auto;
        display: block;
        font-size: 12px;
        text-align: right;
      }

      p {
        font-size: 14px;
      }

      .card {
        width: 368px;
        height: 220px;
        margin-top: 20px;
        height: 100%;
        padding: 15px;
      }
    }

    .message {
      width: 100%;
    }

    .new-message {
      display: flex;
      justify-content: space-between;

      @media (max-width: 991px) {
        display: block;
        position: relative;
      }

      .new-message-input {

        width: 100% !important;
        background: transparent !important;
        border-radius: 8px !important;

        textarea {
          font-size: 14px !important;
          // color: transparent !important;
        }


      }

      .text-area {
        padding: 15px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: black;
        font-family: inherit;
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
      }

      button {
        // height: 30px;
        margin: auto 0px auto 10px;
        color: white;
        background: $MainColor;
        border: $MainColor;
        min-width: 120px !important;

        @media (max-width: 991px) {
          margin-top: 20px;
          margin-left: auto;
          position: absolute;
          right: 6px;
          top: 68px;
          width: 40px !important;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 40px !important;
          margin: 0 ;
        }
      }

      button:disabled,
      button[disabled] {
        border: 1px solid #cccccc;
        background-color: #cccccc;
        color: #666666;
      }

      .input-message {
        width: 100%;
        position: relative;

        .attachement {
          cursor: pointer;
          position: absolute;
          right: 10px;
          bottom: 5px;
          z-index: 999;

          input {
            display: none;
          }
        }
      }
    }

    .message-attachements {
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        li {
          border: 1px dashed;
          padding: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 12px;
          position: relative;
          border-radius: 8px;

          button {
            position: absolute;
            right: 0;
            top: 0;
            background: none;

            svg {
              background: none;
              color: $MainColor;
              width: 16px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}