@import "/src/styles/vars.scss";
.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 50px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .icon {
    color: $MainColor;
    width: 20px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
