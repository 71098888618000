.filter-section{
    
    .filter-reselt{
        justify-content: space-between;
        
        span{
            font-size: 12px;
            font-size: 10px;
            color: gray;
    
        }
        .select{
            height: 20px;
            width: 100px;
        }
    }
}