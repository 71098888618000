@import "/src/styles/vars.scss";
.company-media{
  @media (max-width:900px){
    margin-top: 50px;
  }
}
  .item {
    background-size: cover;
    background-position: bottom;
    height: 200px;
    width: 100%;
    position: relative;
    border-radius:8px;
  }
  .overlay {
    border-radius:8px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    background: #00000066;
    svg {
      color: $MainColor;
      height: 50px;
      width: 50px;
      margin: auto;
      cursor: pointer;
    }
  }
