.jobs {
  display: block;
  text-align: center;
  padding-top: 50px;
  // .tabs {
  //   .tab {
  //     text-transform: none;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 24px;
  //     color: #222222;
  //     margin: 6px 12px;
  //     display: flex;
  //     justify-content: end;
  //     padding: 0;
  //     min-width: auto;
  //   }
  // }
  .actions {
    display: flex;
    justify-content: center;
    padding: 28px 0px 50px 0px;
  }

}

