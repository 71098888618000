@import "/src/styles/vars.scss";

.post {
  padding: 0px;
  box-shadow: none !important;
  background: #ffffff;
  border: 1.25px solid #efeeee;
  border-radius: 16px !important;
  width: 100%;
  padding:17px;
  height: 100%;
  min-height: 235px !important;
  .card_link{
    text-decoration: none;
    cursor: pointer;
    .content {
      display: block;
     
      img {
        width: 50px;
        height: 100%;
        margin: 10px 0;
      }
      padding: 0px !important;
      width: inherit;
      .card_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;

        span {
          display: block;
          margin: auto 0;
          text-align: left;
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: #6a6a6a;
        }
        .icon {
          color: $MainColor;
          padding: 0;
          width: 20px;
          height: 20px;
          svg{
            width: 100%;
            height: 100%;
          }
        }
      }
      .job {
        text-align: left;
        h3 {
          color: $MainColor;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 2px;
        }
        h4 {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          margin: 0;
        }
        margin: 0px 0px 25px 0px;
      }
      .info {
        .compny {
          text-align: left;
          h3 {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            margin: 0;
          }
          h4 {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            margin-top: 5px;
          }
        }
        .text_right {
          text-align: right;
        }
      }
    }
  }
  
}
