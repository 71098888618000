@import "/src/styles/vars.scss";
.container {
  .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;
      margin-bottom: 50px;
  
      .icon {
        color: $MainColor;
        padding: 0;
        width: 30px;
        height: 30px;
  
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
 
  .recrutier-card {
    margin: auto;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    background-color: #F2640008;
    align-items: center;
    justify-content: center;
    padding: 30px;
    justify-content: space-between;
    text-align: center;
    row-gap: 1rem;
    border-radius: 8px;
    border:$MainBorder;
   
   
  }
  .job-description {
    margin-bottom: 50px;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 48px;
      color: $TextColor;
    }
  }
  a, .show {
    color: $MainColor;
    text-decoration: underline;
    display: block;
    cursor: pointer;
  }
}
.logo{
  width: 100px;
  height: 100px;
  margin-right: 15px;
}
