@import "/src/styles/vars.scss";

.banner {
  background: $backgroundColor;
  height: 100%;
  display: block;
  padding: 20px;
 
  .logo {
    display: flex;
    padding: 20px 0;
    img {
      width: 120px;
    }
  }
  .content {
    // color:white;
    display: flex;
    align-content: center;
    h2 {
      font-size: 24px;
      margin: 10px 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      transform: scale(0.94);
      animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);

      @keyframes scale {
        100% {
          transform: scale(1);
        }
      }

      li {
       
        margin-bottom: 20px;
        display: inline-block;
        opacity: 0;
        filter: blur(4px);
        &:nth-child(1) {
          animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        &:nth-child(2) {
          animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        &:nth-child(3) {
          animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        &:nth-child(4) {
          animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        h3 {
          margin: 5px 0px;
          font-size: 16px;
          font-weight: 700;
          line-height: 30px;
        }
        p {
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
